
import { fpjsPlugin } from '@fingerprintjs/fingerprintjs-pro-vue-v3'

export default defineNuxtPlugin((nuxt) => {
  const apiKey = '5J4YyNl4muP05j0Pc1Da'

  const env = useRuntimeConfig().public.environment
  if (env !== 'production' && env !== 'staging') {
    log.debug(`Not loading fingerprintjs in ${env} environment`)
    return
  }

  nuxt.vueApp.use(fpjsPlugin, {
    loadOptions: {
      apiKey,
      endpoint: 'https://f.enigmalabs.digital'
    }
  })
})
