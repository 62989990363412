import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/builds/web/incident-submission-v2/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/builds/web/incident-submission-v2/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/builds/web/incident-submission-v2/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules__64hexdigital_nuxt_segment_dist_runtime_plugin_client_mjs_Iz5je2VyIc from "/builds/web/incident-submission-v2/node_modules/@hexdigital/nuxt-segment/dist/runtime/plugin.client.mjs";
import node_modules__64nuxtjs_device_dist_runtime_plugin_mjs_t2GMTTFnMT from "/builds/web/incident-submission-v2/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import node_modules__64nuxtjs_apollo_dist_runtime_plugin_mjs_eTVJQYlCmx from "/builds/web/incident-submission-v2/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugins_apollo_error_ts_xteZhA1Hlb from "/builds/web/incident-submission-v2/plugins/apollo-error.ts";
import plugins_fingerprint_client_ts_mCHejvGXC2 from "/builds/web/incident-submission-v2/plugins/fingerprint.client.ts";
import plugins_ga_ts_4BO90vZJZB from "/builds/web/incident-submission-v2/plugins/ga.ts";
import plugins_log_client_ts_WMRIWGjkP5 from "/builds/web/incident-submission-v2/plugins/log.client.ts";
import plugins_sentry_client_ts_shVUlIjFLk from "/builds/web/incident-submission-v2/plugins/sentry.client.ts";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules__64hexdigital_nuxt_segment_dist_runtime_plugin_client_mjs_Iz5je2VyIc,
  node_modules__64nuxtjs_device_dist_runtime_plugin_mjs_t2GMTTFnMT,
  node_modules__64nuxtjs_apollo_dist_runtime_plugin_mjs_eTVJQYlCmx,
  plugins_apollo_error_ts_xteZhA1Hlb,
  plugins_fingerprint_client_ts_mCHejvGXC2,
  plugins_ga_ts_4BO90vZJZB,
  plugins_log_client_ts_WMRIWGjkP5,
  plugins_sentry_client_ts_shVUlIjFLk
]